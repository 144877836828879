import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import SearchBox from '../SearchBox'

const ItemsSearchBox = ({data}) => (
  <StaticQuery
    query={graphql`
            query SearchIndexQuery {
                siteSearchIndex {
                    index
                }
            }
        `}
    render={data => (
      <SearchBox searchIndex={data.siteSearchIndex.index} />
    )}
  />
  // <h1>H1 {JSON.stringify(data, null, 2)}</h1>
)

export default ItemsSearchBox
