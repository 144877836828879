import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import SearchBox from '../SearchBox'
import ItemsSearchBox from '../ItemsSearchBox/Index'

const NavBar = ({ toggleNavbar, isActive }) => (
  <nav className='navbar is-fixed-top' aria-label='main navigation'>
    <div className='navbar-brand'>
      <Link to='/' className='navbar-item'>
        <strong>Lista rzeczy</strong>
      </Link>
      <button
        className={`button navbar-burger ${isActive ? 'is-active' : ''}`}
        data-target='navMenu'
        onClick={toggleNavbar}
      >
        <span />
        <span />
        <span />
      </button>
    </div>
    <div className={`navbar-menu ${isActive ? 'is-active' : ''}`} id='navMenu'>

      <div className='navbar-end'>
        <ItemsSearchBox />

        <Link className='navbar-item' to='/o-nas'>
                          O nas
        </Link>
        <Link className='navbar-item' to='/co-zabrac'>
                            Przeglądaj listy
        </Link>
        <div className='navbar-item'>
          <div className='field is-grouped'>
            <p className='control'>
              <Link
                className='button is-primary is-outlined'
                to='/kontakt'>
                            Kontakt
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </nav>
)

export default NavBar
